import { Loader } from "@googlemaps/js-api-loader";

import jsonStyles from "../../gmap-style/style-gmap.json";

document.addEventListener("DOMContentLoaded", () => {
  const mapDiv = document.getElementById("map");

  if (!mapDiv) {
    return;
  }

  const keyElement = document.getElementById("hide-map");
  const key = keyElement.getAttribute("data-map");

  const rawLat = keyElement.getAttribute("data-lat");
  const rawLong = keyElement.getAttribute("data-long");

  const lat = parseFloat(rawLat);
  const long = parseFloat(rawLong);

  const loader = new Loader({
    apiKey: key,
    version: "weekly",
    librairies: ["marker"],
  });

  loader.load().then(() => {
    const map = new google.maps.Map(mapDiv, { // eslint-disable-line
      center: { lat, lng: long },
      zoom: 15,
      styles: jsonStyles,
    });

    const marker = new google.maps.Marker({ // eslint-disable-line
      position: { lat, lng: long },
      icon: {
        path: google.maps.SymbolPath.CIRCLE, // eslint-disable-line
        scale: 10,
        strokeColor: "#656C5F",
      },
      title: "Centre Santé du cheveu",
    });

    marker.setMap(map);
  });
});
